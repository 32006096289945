<template>
  <div class="MateContent materialGallery_csss h100">
    <el-tabs v-model="activeName"
             class="h100"
             type="border-card"
             @tab-click="tabIndex">
      <el-tab-pane name="0"
                   label="素材图库">
        <el-upload list-type="picture-card"
                   :action="$website.urlMaterial + '/material/uploadMaterial?type=1'"
                   :file-list="materialListData.records"
                   :on-success="onSuccessMaterialGallery"
                   class="materialGallery_csss"
                   :data="{ type: 1 }"
                   multiple>
          <i slot="default"
             class="el-icon-plus"></i>
          <div slot="file"
               slot-scope="{ file }">
            <img class="el-upload-list__item-thumbnail"
                 style="height: 100%"
                 :src="file.frontPath"
                 alt="" />
            <span class="el-upload-list__item-actions">
              <span class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file, 1)">
                <i class="el-icon-zoom-in"></i>
              </span>
              <span class="el-upload-list__item-delete"
                    @click="handleRemove(file)">
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </el-upload>
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="page.currentPage"
                       :page-sizes="[10, 200, 300, 400]"
                       :page-size="page.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="page.total">
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane name="1"
                   label="素材视频">
        <el-upload class="upload-demo"
                   :on-success="onSuccessMaterGalleryvideo"
                   :data="{ type: 0 }"
                   :action="$website.urlMaterial + '/material/uploadMaterial?type=0'">
          <el-button size="small"
                     type="primary">点击上传</el-button>
          <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
        </el-upload>
        <div class="el-upload-list el-upload-list--picture-card">
          <el-card class="box-card">
            <el-table :data="videoLibraryData.records"
                      border
                      height="60vh"
                      style="width: 100%;"
                      size="mini">
              <el-table-column prop="name"
                               label="设备名称"> </el-table-column>
              <el-table-column prop="frontPath"
                               label="播放地址"> </el-table-column>
              <el-table-column fixed="right"
                               label="操作"
                               width="100">
                <template slot-scope="scope">
                  <el-button type="text"
                             @click="handlePictureCardPreview(scope.row, 2)"
                             size="small">查看</el-button>
                  <el-button type="text"
                             @click="handleRemove(scope.row, true)"
                             size="small">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"
                           :current-page="page.currentPage"
                           :page-sizes="[10, 200, 300, 400]"
                           :page-size="page.size"
                           layout="total, sizes, prev, pager, next, jumper"
                           :total="page.total">
            </el-pagination>
          </el-card>
        </div>

      </el-tab-pane>
      <el-tab-pane name="2"
                   label="素材流视频">
        <div>
          <!-- identify -->
          <el-form :model="form"
                   ref="ruleForm"
                   :inline="true"
                   :rules="rules"
                   label-width="100px">
            <el-form-item label="流地址"
                          prop="url">
              <el-input v-model="form.url"></el-input>
            </el-form-item>
            <el-form-item label="设备序列号"
                          prop="identify">
              <el-input v-model="form.identify"></el-input>
            </el-form-item>
            <el-form-item label="名称"
                          prop="name">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary"
                         @click="materialmupdate">添加素材流视频</el-button>
            </el-form-item>
          </el-form>
        </div>

        <div class="el-upload-list el-upload-list--picture-card">
          <el-card class="box-card">
            <el-table :data="streamVideoData.records"
                      border
                      height="60vh"
                      style="width: 100%;"
                      size="mini">
              <el-table-column prop="identify"
                               label="设备序列号"
                               width="200">
              </el-table-column>
              <el-table-column prop="name"
                               label="设备名称"> </el-table-column>
              <el-table-column prop="url"
                               label="播放地址"> </el-table-column>
              <el-table-column fixed="right"
                               label="操作"
                               width="100">
                <template slot-scope="scope">
                  <el-button type="text"
                             @click="handlePictureCardPreview(scope.row, '', true)"
                             size="small">查看</el-button>
                  <el-button type="text"
                             @click="handleRemoveVideo(scope.row)"
                             size="small">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"
                           :current-page="page.currentPage"
                           :page-sizes="[10, 200, 300, 400]"
                           :page-size="page.size"
                           layout="total, sizes, prev, pager, next, jumper"
                           :total="page.total">
            </el-pagination>
          </el-card>
        </div>
      </el-tab-pane>
    </el-tabs>
    <el-dialog width="80%"
               :visible.sync="isDdit"
               append-to-body>
      <el-input placeholder="请输入内容"
                validate-event
                clearable
                v-model="dialogImageUrl.url">
        <template slot="append">
          <div @click="materialmupdate(true)">修改素材流视频</div>
        </template>
      </el-input>
    </el-dialog>
    <el-dialog :width="activeName != 2 ? '30%' : '50%'"
               @close="pauseisVideoSee"
               :visible.sync="dialogVisible"
               append-to-body>
      <img v-if="activeName == 0"
           width="100%"
           :src="dialogImageUrl"
           alt="" />
      <video width="100%"
             v-if="activeName == 1"
             id="video_cosnst"
             controls=""
             autoplay=""
             name="media">
        <source :src="dialogImageUrl"
                type="video/mp4" />
      </video>
      <div v-if="dialogVisible && activeName == 2 && IsstreamVideoData"
           style="display: inline-block; height: 60vh; width: 100%">
        <videoModel v-if="dialogVisible && activeName == 2 && IsstreamVideoData"
                    :item="dialogImageUrl"></videoModel>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { imgOption } from "@/option/config";
import {
  materialList,
  materialRemove,
  materialStreamlist,
  materialStreamremove,
  materialStreamsave,
} from "@/api/material";
import videoModel from "@/page/group/videoModel";
export default {
  components: {
    videoModel,
  },
  data () {
    return {
      activeName: "0",
      imgOption: imgOption,
      materialListData: [], //素材图库
      videoLibraryData: [], //素材视频库
      streamVideoData: [], //素材流视频
      dialogVisible: false,
      IsstreamVideoData: false,
      isDdit: false, //视频编辑
      dialogImageUrl: {},
      page: {
        total: 0,
        currentPage: 1,
        page: 1,
        size: 10,
      },
      form: {
        url: "",
        identify: "",
        name: "",
      },
      rules: {
        url: [{ required: true, message: "请输入流地址", trigger: "blur" }],
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        identify: [{ required: true, message: "请输入设备序列号", trigger: "blur" }],
      },
    };
  },
  computed: {
    styleName () {
      return {
        width: "200px",
      };
    },
  },
  mounted () {
    this.handOponContent(1);
  },
  methods: {
    handleCurrentChange (val) {
      this.page.page = val;
      this.tabIndex({
        index: this.activeName,
        isTruew: true,
      });
    },
    handleSizeChange (val) {
      this.page.size = val;
      this.tabIndex({
        index: this.activeName,
        isTruew: true,
      });
    },
    tabIndex (el) {
      console.log(el.isTruew, this.page);
      if (!el.isTruew) {
        this.page = {
          total: 0,
          currentPage: 1,
          page: 1,
          size: 10,
        };
      }
      if (el.index == 0) {
        this.handOponContent(1);
      } else if (el.index == 1) {
        this.handOponContent(0);
      } else if (el.index == 2) {
        this.getmaterialStreamlist(0);
      }
    },
    getmaterialStreamlist () {
      this.IsstreamVideoData = false;
      materialStreamlist({
        ...this.page,
      }).then((res) => {
        this.streamVideoData = res.data;
        this.page.total = res.data.total;
        this.page.currentPage = res.data.current;
        this.IsstreamVideoData = true;
      });
    },
    pauseisVideoSee () {
      if (this.activeName == 1) {
        document.getElementById("video_cosnst").pause();
      }
    },
    tabConent (el, type) {
      if (type != true) {
        this.typeOff = el;
      }
      if (el == 2) {
        this.handOponContent(1);
      } else if (el == 3) {
        this.handOponContent(0);
      } else if (el == 4) {
        this.getmaterialStreamlist();
      }
    },
    handlePictureCardPreview (file, type, isCs) {
      this.dialogImageUrl = {}
      if (isCs) {
        this.dialogImageUrl = file;
        this.dialogVisible = true;
        return false;
      }
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
      document.getElementById("video_cosnst").load();
    },
    handleDdit (file) {
      this.dialogImageUrl = {}
      this.dialogImageUrl = file;
      this.isDdit = true;
    },
    onSuccessMaterGalleryvideo () {
      this.tabConent(3, true);
    },
    onSuccessMaterialGallery (el) {
      if (el) {
        this.tabConent(2, true);
      }
    },
    materialmupdate (el) {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          materialStreamsave(
            el === true ? this.dialogImageUrl : this.form
          ).then(() => {
            this.$message({
              type: "success",
              message: "成功!",
            });
            this.getmaterialStreamlist(0);
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      return false;
    },
    handleRemove (file, type) {
      let _this = this;

      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          materialRemove({ id: file.id }).then((res) => {
            console.log(res);
            if (res.code == 200 && res.msg == "OK") {
              if (type == true) {
                _this.videoLibraryData.records =
                  _this.videoLibraryData.records.filter((item) => {
                    return item.id != file.id;
                  });
              } else {
                _this.materialListData.records =
                  _this.materialListData.records.filter((item) => {
                    return item.id != file.id;
                  });
              }
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          }).catch((res) => {
            console.log(res)
            this.$message({
              type: "error",
              message: res || JSON.stringify(res),
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
      return false;
    },
    handleRemoveVideo (file) {
      let _this = this;
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          materialStreamremove({ id: file.id }).then((res) => {
            if (res.code == 200 && res.msg == "OK") {
              _this.streamVideoData.records =
                _this.streamVideoData.records.filter((item) => {
                  return item.id != file.id;
                });
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
      return false;
    },
    handOponContent (type) {
      let _this = this;
      console.log(this.page)
      materialList({
        ...this.page,
        type: type,
      }).then((res) => {
        this.page.total = res.data.total;
        this.page.currentPage = res.data.current;
        if (type == 1) {
          res.data.records = res.data.records.map((element) => {
            return {
              ...element,
              url: this.$website.imgrlMaterial + "/" + element.frontPath,
              name: element.originName,
            };
          });
          _this.materialListData = res.data;
        } else if (type == 0) {
          res.data.records = res.data.records.map((element) => {
            return {
              ...element,
              url: this.$website.imgrlMaterial + "/" + element.frontPath,
              name: element.originName,
            };
          });
          this.videoLibraryData = res.data;
        }
      });
    },
  },
};
</script>
<style>
.tabCsliy .el-tag--plain {
  background-color: transparent !important;
  border-color: #2681ff !important;
}
.tabArrCnent .m {
  margin-right: 20px;
}
.materialGallery_csss .el-upload-list__item img {
  margin: 0 !important;
  padding: 0 !important;
}
.materialGallery_csss .el-upload-list__item.is-success,
.materialGallery_csss .el-upload-list__item.is-ready {
  display: none;
}
.videoThsskj {
  display: flex;
  align-items: center;
}
.materialGallery_csss .thumbnailVideo {
  width: 100%;
}
.materialGallery .el-tabs__content {
  height: 100%;
}
</style>

