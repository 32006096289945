<template>
  <div  style="height: 100%; width: 100%;">
    <div :id="hid"
    style="height: 100%; width: 100%;"
         :style="styleChartName"></div>
  </div>
</template>

<script>
import { uuid } from '@/utils/utils';

export default {
    props:{
        item: {
            type: [Object,Array,JSON],
            default: () => {
                return {}
            }
        }
    },
    computed: {
    autoplay () {
      return this.option.autoplay
    }
  },
    watch:{
       item:{
           handler(e){
               setTimeout(() => {
                  this.player = new Clappr.Player({
                        parentId: '#'+this.hid,
                        source:e.url,
                        autoPlay:false,
                        mute: true,
                        height: '100%',
                        width: '100%'
                    });
                })
                
           },
           immediate: true
       } 
    },
    data () {
        return {
            hid: 'main_' + uuid(),
            reload: true,
            player:'player_'+uuid(),
            config: {},
            styleChartName:{},
        }
   },
   created(){
       
   },
   beforeDestroy(){
       this.player.stop()
   },
   methods:{
   }
    
}
</script>



